<template>
  <div class="">
   <div class="card-body overflow-hidden p-lg-4">
      <div class="row align-items-center justify-content-between">
        <div class="col-12 text-center">
          <h4 class="mb-5">
            Мы в процессе обновления, уже совсем сокоро!
          </h4>
        </div>
        <div class="col-3"></div>
        <div class="col-6">
          <img class="img-fluid" src="@/assets/img/illustrations/2.svg" alt="">
        </div>
        <div class="col-3"></div>
        <div class="col-2"></div>
        <div class="col-8 text-center">
          <h6 class="mt-5">
            Благодаря Вашим отзывам, мы постоянно улучшаем наш продукт, чтобы Вы могли еще легче превращать идеи в практичные бизнес-планы!!! 
          </h6>
        </div>
        <div class="col-2"></div>
      </div>
   </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>